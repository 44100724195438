<template>
  <div class="vg_wrapper" v-loading="loadingFlag">
    <div class="vd_edhea vg_button_group">
      <editHeader :isShow="isShow" :btn="btn" :strForm="tinvForm" @openEdit="openEdit" @closeEdit="closeEdit" @submit="submit('tinvForm')" />
      <div class="vd_export" v-if="isShow">
        <el-button type="primary" size="small" @click="buttonRefresh()">刷新</el-button>
        <el-button type="primary" size="small" @click="exportExcel()">打印预览</el-button>
      </div>
      <div class="vd_export2 vg_mr_8">
        <span style="margin-right: 2px">费用单号:</span>
        <el-input size="mini" style="width: 150px" disabled v-model="tinvForm.tinv_no" show-word-limit></el-input>
      </div>
    </div>
    <el-form ref="tinvForm" :model="tinvForm" :rules="rules" label-width="120px" size="mini" :disabled="isShow">
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="用友对接状态" prop="yongYouStatus" v-if="yongYouStatus === '已抓取'">
            <el-input v-model="yongYouStatus" type="input" disabled> </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="出运发票号" prop="tran_no">
            <el-input disabled :value="tinvForm.tran_no" maxlength="30" show-word-limit placeholder="请填写出运发票号">
              <template slot="append">
                <el-link type="primary" @click="dialogTableVisible = true" :disabled="isShow">导入</el-link>
              </template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="发票号码" prop="pinv_actu_no">
            <el-input v-model="tinvForm.pinv_actu_no" maxlength="30" show-word-limit placeholder="请填写发票号码"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="发票日期" prop="pinv_date">
            <el-date-picker v-model="tinvForm.pinv_date" type="date" placeholder="选择发票日期"></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="发票种类" prop="tinv_invtype">
            <el-select v-model="tinvForm.tinv_invtype" size="small" placeholder="请选择发票种类" filterable>
              <el-option v-for="item in tinvInvtypeList" :key="item.id" :value="item.id" :label="item.label"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="开票状态" prop="pinv_invstat">
            <el-select v-model="tinvForm.pinv_invstat" size="small" placeholder="请选择开票状态" filterable>
              <el-option v-for="item in pinvInvstatList" :key="item.id" :value="item.id" :label="item.label"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="收票日期" prop="pinv_recdate">
            <el-date-picker v-model="tinvForm.pinv_recdate" type="date" placeholder="选择收票日期"></el-date-picker>
          </el-form-item>
        </el-col>
        <!--        <el-col :md="8">
          <el-form-item label="收票日期" prop="ppay_rpdate">
            <el-date-picker v-model="tinvForm.ppay_rpdate" type="date" placeholder="选择付款日期"> </el-date-picker>
          </el-form-item>
        </el-col>-->
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="发票金额" prop="pinv_part_subtotal">
            <el-input disabled v-model="tinvForm.pinv_part_subtotal" maxlength="9" show-word-limit placeholder="请填写本币金额"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="所属部门" prop="tinv_dept_id">
            <el-select v-model="tinvForm.tinv_dept_id" size="small" placeholder="请选择所属部门" filterable>
              <el-option v-for="item in deptList" :key="item.dept_id" :value="item.dept_id" :label="item.dept_name"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <!-- <el-col :md="8">
          <el-form-item label="实际总额" prop="pinv_total">
            <el-input disabled v-model="tinvForm.pinv_total" maxlength="13" show-word-limit placeholder="请填写实际总额">
            </el-input>
          </el-form-item>
        </el-col> -->
      </el-row>
      <el-row class="vg_mb_5">
        <!-- <el-col :md="8">
          <el-form-item label="实际总净重" prop="pinv_tnweight">
            <el-input disabled v-model="tinvForm.pinv_tnweight" maxlength="9" show-word-limit placeholder="请填写实际总净重">
            </el-input>
          </el-form-item>
        </el-col> -->
        <el-col :md="16">
          <el-form-item label="源发票号码" prop="pinv_actu_nos">
            <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 6 }"
              maxlength="255"
              show-word-limit
              disabled
              v-model="tinvForm.pinv_actu_nos"
              placeholder="请填写出运发票号"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="vd_dash"></div>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="公司抬头" prop="cptt_aname">
            <el-select v-model="tinvForm.cptt_aid" size="small" placeholder="请选择公司抬头" filterable>
              <el-option v-for="item in cpttOptionList" :key="item.cptt_id" :value="item.cptt_id" :label="item.cptt_name"></el-option>
            </el-select>
            <!--            <el-input v-model="tinvForm.cptt_aname" show-word-limit placeholder="请选择公司抬头"></el-input>-->
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="海关编码" prop="prod_hscode">
            <el-input readonly v-model="tinvForm.prod_hscode" maxlength="30" show-word-limit placeholder="请填写海关编码">
              <template slot="append">
                <el-link type="primary" @click="dialogTableVisible2 = true" :disabled="isShow">导入</el-link>
              </template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="转开报关品名" prop="prod_hsname">
            <el-input v-model="tinvForm.prod_hsname" maxlength="20" show-word-limit placeholder="请填写转开报关品名"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="转开抬头" prop="cptt_bid">
            <el-select v-model="tinvForm.cptt_bid" size="small" placeholder="请选择转开抬头" filterable>
              <el-option v-for="item in cpttOptionList" :key="item.cptt_id" :value="item.cptt_id" :label="item.cptt_name"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="转开票类型" prop="tinv_type">
            <el-select v-model="tinvForm.tinv_type" size="small" placeholder="请选择转开抬头" filterable>
              <el-option v-for="item in tinvTypeList" :key="item.id" :value="item.id" :label="item.label"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="开票单位" prop="pinv_part_unit">
            <el-select v-model="tinvForm.pinv_part_unit" size="small" placeholder="请选择转开抬头" filterable>
              <el-option v-for="item in CompanyList" :key="item.id" :value="item.param1" :label="item.param1"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="转开票金额" prop="tinv_invtotal">
            <el-input disabled v-model="tinvForm.tinv_invtotal" maxlength="9" show-word-limit placeholder="请填写转开票金额"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="总净重" prop="prod_tnweight">
            <el-input v-model="tinvForm.prod_tnweight" disabled maxlength="9" show-word-limit placeholder="请填写总净重"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="总数量" prop="pinv_num">
            <el-input v-model="tinvForm.pinv_num" disabled maxlength="9" show-word-limit placeholder="请填写总数量"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="退税率" prop="prod_hsoutrate">
            <el-input disabled v-model="tinvForm.prod_hsoutrate" maxlength="7" show-word-limit placeholder="请填写退税率"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="增值税率" prop="prod_hsinrate">
            <el-input disabled v-model="tinvForm.prod_hsinrate" maxlength="7" show-word-limit placeholder="请填写增值税率"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="税额合计" prop="pinv_taxtotal">
            <el-input disabled v-model="tinvForm.pinv_taxtotal" maxlength="13" show-word-limit placeholder="请填写税额合计"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="vd_dash"></div>
      <el-row class="vg_mb_5">
        <el-col>
          <div class="vg_mb_16">
            <el-button type="primary" plain size="small" @click="openPurce">导入发票明细</el-button>
            <el-button type="danger" plain size="small" @click="delPurce">删除</el-button>
          </div>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="24">
          <InvoiceList
            ref="InvoiceListRef"
            v-if="tinvForm.tinv_part_list"
            :tinvForm="tinvForm"
            @handleSelectionChange="handleSelectionChange"
            @tinvInvtotalChange="calcEcho"
          ></InvoiceList>
        </el-col>
      </el-row>
      <el-dialog title="导入出运发票号" width="70%" :visible.sync="dialogTableVisible">
        <TranList @childConfirm="childConfirm"></TranList>
      </el-dialog>
      <el-dialog title="导入采购发票明细" width="70%" :visible.sync="dialogTableVisible1">
        <PurchaesList
          :tranNo="tinvForm.tran_no"
          :dialogTableVisible1="dialogTableVisible1"
          @childConfirmPurchaes="childConfirmPurchaes"
          @childChanelPurchaes="childChanelPurchaes"
          ref="PurchaesList"
        ></PurchaesList>
      </el-dialog>
      <el-dialog title="导入海关编码" width="70%" :visible.sync="dialogTableVisible2">
        <CustomsList @changeCustom="changeCustom" ref="PurchaesList"></CustomsList>
      </el-dialog>
      <el-row class="vg_mt_16 vd_bortop">
        <el-col>
          <inputUser :isCookie="false" :stffForm="stffForm" ref="userData" v-if="tinvForm.stff_name"></inputUser>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { tinvAPI } from '@api/modules/tinv';
import { optnAPI } from '@api/modules/optn';
import { cpttAPI } from '@api/modules/comptitle';
import { stffAPI } from '@/api/modules/staff';
import editHeader from '@/views/component/editHeader';
import inputUser from '@/views/component/inputUser';
import { BigNumber } from 'bignumber.js';
import InvoiceList from './Component/InvoiceList.vue';
import { useCpttMixin, useCustMixin, useDeptMixin, useStffMixin, useSuppMixin } from '@/assets/js/mixin/initMixin';
import TranList from './Component/TranList.vue';
import PurchaesList from './Component/PurchaesList.vue';
import CustomsList from './Component/CustomsList.vue';
import helper from '@assets/js/helper';
import { deptAPI } from '@api/modules/department';
import { downloadFile } from '@api/public';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'tinvEditMain',
  components: {
    editHeader,
    inputUser,
    InvoiceList,
    TranList,
    PurchaesList,
    CustomsList
  },
  mixins: [useSuppMixin, useCustMixin, useStffMixin, useDeptMixin, useCpttMixin],
  data() {
    return {
      rules: {
        cptt_aid: [{ required: true, trigger: ['blur', 'change'], message: ' ' }],
        prod_hscode: [{ required: true, trigger: 'change', message: ' ' }],
        cptt_bid: [{ required: true, trigger: ['blur', 'change'], message: ' ' }],
        tinv_type: [{ required: true, trigger: ['blur', 'change'], message: ' ' }],
        pinv_part_unit: [{ required: true, trigger: ['blur', 'change'], message: ' ' }],
        podr_dept_id: [{ required: true, trigger: ['blur', 'change'], message: ' ' }],
        cust_id: [{ required: true, trigger: ['blur', 'change'], message: ' ' }],
        pinv_date: [{ required: true, trigger: ['blur', 'change'], message: ' ' }],
        pinv_recdate: [{ required: true, trigger: ['blur', 'change'], message: ' ' }],
        tinv_dept_id: [{ required: true, trigger: ['blur', 'change'], message: ' ' }]
      },
      tinvForm: {
        tran_no: ''
      },
      isShow: true,
      btn: {},
      stffForm: {},
      loadingFlag: false,
      tinvInvtypeList: [
        { id: 1, label: '增值税发票' },
        { id: 2, label: '普通发票' }
      ],
      pinvInvstatList: [
        { id: 0, label: '可开票' },
        { id: 1, label: '已开票' },
        { id: 2, label: '部分开票' }
      ],
      tinvTypeList: [
        { id: 1, label: '开净重' },
        { id: 2, label: '开数量' }
      ],
      stffList: [],
      cpttList: [],
      tinvAttrList: [
        { id: 1, label: '包干费' },
        { id: 2, label: '测试费' }
      ],
      deptList: [],
      CompanyList: [],
      dialogTableVisible: false,
      dialogTableVisible1: false,
      dialogTableVisible2: false,
      selectList: [],
      tinvPartIdCopy: -1,
      tinvPartListCopy: [],
      currencyList: [],
      yongYouStatus: '未抓取'
    };
  },
  created() {
    this.initData();
  },
  watch: {},
  methods: {
    initData() {
      this.loadingFlag = true;
      this.getTinvInfo();
      this.getCptt();
      this.getStffUser();
      this.getCustDport();
      this.getCurrency();
      this.getDept();
    },
    //启用编辑
    openEdit() {
      this.isShow = false;
      this.$emit('isShow', this.isShow);
    },
    // 获取部门
    getDept() {
      get(deptAPI.getAllDeptsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.deptList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    //撤销编辑
    closeEdit() {
      this.$confirm('是否撤销编辑?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.isShow = true;
          this.$emit('isShow', this.isShow);
          this.initData();
          this.$message({
            type: 'info',
            message: '已撤销编辑!'
          });
        })
        .catch(err => console.error(err));
    },
    //提交表单
    submit(formName) {
      this.$confirm('确认保存?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$refs[formName].validate(valid => {
            if (valid) {
              this.saveInfo();
            } else {
              console.log('error submit!');
              this.$message.warning('请把必填内容补充完整');
              return false;
            }
          });
        })
        .catch(() => {});
    },
    // 保存
    saveInfo() {
      //计算回显值
      this.calcEcho();
      let tinvForm = JSON.parse(JSON.stringify(this.tinvForm));
      tinvForm.tinv_date = parseInt(Number(new Date(tinvForm.tinv_date).getTime()) / 1000);
      if (tinvForm.ppay_rpdate) {
        tinvForm.ppay_rpdate = parseInt(Number(new Date(tinvForm.ppay_rpdate).getTime()) / 1000);
      }
      if (tinvForm.pinv_date) {
        tinvForm.pinv_date = parseInt(Number(new Date(tinvForm.pinv_date).getTime()) / 1000);
      }
      if (tinvForm.pinv_recdate) {
        tinvForm.pinv_recdate = parseInt(Number(new Date(tinvForm.pinv_recdate).getTime()) / 1000);
      }
      tinvForm.cptt_aname = this.cpttOptionList.find(({ cptt_id }) => cptt_id === tinvForm.cptt_aid)?.cptt_name;
      tinvForm.cptt_bname = this.cpttOptionList.find(({ cptt_id }) => cptt_id === tinvForm.cptt_bid)?.cptt_name;
      // for(let i=0;i<tinvForm.tinv_actu_list.length;i++){
      //   if(tinvForm.tinv_actu_list[i].pinv_actu_date){
      //     tinvForm.tinv_actu_list[i].pinv_actu_date = parseInt(Number(new Date(tinvForm.tinv_actu_list[i].pinv_actu_date).getTime())/1000)
      //   }
      // }
      this.tinvPartListCopy = this.tinvPartListCopy.filter(item => tinvForm.tinv_part_list.every(item1 => item.tinv_part_id !== item1.tinv_part_id));
      for (let i in this.tinvPartListCopy) {
        this.tinvPartListCopy[i].destroy_flag = 1;
      }
      tinvForm.tinv_part_list = tinvForm.tinv_part_list.concat(this.tinvPartListCopy);
      this.$refs.InvoiceListRef.searchForm.invoice_no = '';
      post(tinvAPI.editTinv, tinvForm)
        .then(res => {
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: '保存成功'
            });
            this.isShow = true;
            this.$emit('isShow', this.isShow);
            this.initData();
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
            this.initData();
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 刷新按钮
    buttonRefresh() {
      this.initData();
    },
    // 选择删除值
    handleSelectionChange(val) {
      this.selectList = JSON.parse(JSON.stringify(val));
    },
    // 打开采购发票明细弹框
    openPurce() {
      if (this.tinvForm.tran_no) {
        this.dialogTableVisible1 = true;
      } else {
        this.$message.warning('请先导入出运发票号');
      }
    },
    // 删除导入发票明细
    delPurce() {
      // this.tinvForm.tinv_actu_list = this.tinvForm.tinv_actu_list.filter(item => this.selectList.every(item1 => item.tinv_part_id !== item1.tinv_part_id));
      this.tinvForm.tinv_part_list = this.tinvForm.tinv_part_list.filter(item => this.selectList.every(item1 => item.pinv_part_id !== item1.pinv_part_id));
      this.calcEcho();
    },
    // 关闭发票明细弹框
    childChanelPurchaes() {
      this.dialogTableVisible1 = false;
    },
    // 导入发票明细
    childConfirmPurchaes(val) {
      let list = JSON.parse(JSON.stringify(val));
      list.forEach(item => {
        item.prod_hsname = item.prod_name;
        item.prod_price = item.pinv_part_price;
        item.prod_hsinrate = item.tax_rate;
        item.prod_hsoutrate = item.tax_refundrate;
        item.prod_refundtax = item.tax_refund;
        item.pinv_part_subtotal = item.pinv_actu_subtotal;
      });
      list = list.filter(item => this.tinvForm.tinv_part_list.every(item1 => item.pinv_part_id !== item1.pinv_part_id));
      for (let i = 0; i < list.length; i++) {
        let totalA = new BigNumber(1.02);
        list[i].tinv_part_id = this.tinvPartIdCopy;
        totalA = totalA.times(list[i].pinv_part_subtotal).toNumber();
        list[i].tinv_invtotal = helper.calcPriceT(totalA);
        this.tinvPartIdCopy--;
      }
      this.tinvForm.tinv_part_list = this.tinvForm.tinv_part_list.concat(list);
      setTimeout(() => this.calcEcho());

      this.dialogTableVisible1 = false;
    },
    // 导入储运发票号
    childConfirm(val) {
      let value = JSON.parse(JSON.stringify(val));
      this.tinvForm.tran_no = value.tran_nos;
      this.tinvForm.cptt_aname = value.cptt_name;
      this.tinvForm.cptt_aid = value.pinv_cptt_id;
      this.calcEcho();
      this.tinvForm.tinv_part_list = [];
      this.tinvForm.pinv_part_subtotal = '';
      this.tinvForm.prod_tnweight = '';
      this.tinvForm.pinv_num = '';
      this.tinvForm.pinv_taxtotal = '';
      this.tinvForm.tinv_invtotal = '';
      this.tinvForm.pinv_actu_nos = '';
      this.dialogTableVisible = false;
    },
    // 海关编码导入
    changeCustom(row) {
      this.tinvForm.prod_hscode = row.param1;
      this.tinvForm.prod_hsname = row.param2;
      this.tinvForm.prod_hsoutrate = row.param5;
      this.tinvForm.prod_hsinrate = row.param4;
      this.dialogTableVisible2 = false;
      //重新计算
      this.calcEcho();
    },
    // 计算回显值
    calcEcho() {
      let totalA = new BigNumber(0);
      let totalB = new BigNumber(0);
      let totalC = new BigNumber(0);
      let totalD = new BigNumber(0);
      let totalE = new BigNumber(0);
      let totalF = new BigNumber(0);
      let totalG = new BigNumber(0);
      let totalH = new BigNumber(0);
      let totalProdHsinrate = new BigNumber(0);
      let temp = [];
      for (let i = 0; i < this.tinvForm.tinv_part_list.length; i++) {
        totalA = totalA.plus(
          Number(this.tinvForm.tinv_part_list[i].pinv_part_num) && Number(this.tinvForm.tinv_part_list[i].prod_tnweight)
            ? this.tinvForm.tinv_part_list[i].pinv_part_num
            : 0
        );
        totalB = totalB.plus(this.tinvForm.tinv_part_list[i].pinv_part_subtotal ? this.tinvForm.tinv_part_list[i].pinv_part_subtotal : 0);
        //退税额
        totalC = totalC.plus(this.tinvForm.tinv_part_list[i].prod_refundtax ? this.tinvForm.tinv_part_list[i].prod_refundtax : 0);
        totalD = totalD.plus(this.tinvForm.tinv_part_list[i].prod_tnweight ? this.tinvForm.tinv_part_list[i].prod_tnweight : 0);
        //增值税率
        totalProdHsinrate = totalC.plus(this.tinvForm.tinv_part_list[i].prod_hsinrate ? this.tinvForm.tinv_part_list[i].prod_hsinrate : 0);
        //转开票金额
        totalF = totalF.plus(this.tinvForm.tinv_part_list[i].tinv_invtotal ? this.tinvForm.tinv_part_list[i].tinv_invtotal : 0);
        totalH = totalH.plus(this.tinvForm.tinv_part_list[i].pinv_actu_tax ? this.tinvForm.tinv_part_list[i].prod_refundtax : 0);
        if (this.tinvForm.tinv_part_list[i].invoice_no) {
          temp.push(this.tinvForm.tinv_part_list[i].invoice_no);
        }
      }
      totalA = totalA.toNumber();
      totalB = totalB.toNumber();
      totalC = totalC.toNumber();
      totalD = totalD.toNumber();
      // for(let i=0;i<this.tinvForm.tinv_actu_list.length;i++){
      //   totalE = totalE.plus(this.tinvForm.tinv_actu_list[i].pinv_actu_nweight?this.tinvForm.tinv_actu_list[i].pinv_actu_nweight:0)
      //   totalF = totalF.plus(this.tinvForm.tinv_actu_list[i].pinv_actu_subtotal?this.tinvForm.tinv_actu_list[i].pinv_actu_subtotal:0)
      //   totalG = totalG.plus(this.tinvForm.tinv_actu_list[i].pinv_actu_subtaxfree?this.tinvForm.tinv_actu_list[i].pinv_actu_subtaxfree:0)
      //   totalH = totalH.plus(this.tinvForm.tinv_actu_list[i].pinv_actu_tax?this.tinvForm.tinv_actu_list[i].pinv_actu_tax:0)
      // }
      totalE = totalE.toNumber();
      totalF = totalF.toNumber();
      totalG = totalG.toNumber();
      totalH = totalH.toNumber();
      let temp1 = JSON.parse(JSON.stringify(totalB));
      temp1 = Math.round(Number(temp1) * 100) / 100;
      this.tinvForm.pinv_part_subtotal = this.helper.reservedDigits(temp1);
      //税额合计 = 转开票金额 / (1 + (增值税率/100)) * (增值税率/100)
      let temp3 = ((totalF / (1 + this.tinvForm.prod_hsinrate / 100)) * this.tinvForm.prod_hsinrate) / 100;
      // let temp3 = JSON.parse(JSON.stringify(totalC));
      // temp3 = Math.round(Number(temp3) * 100) / 100;
      this.tinvForm.pinv_taxtotal = this.helper.reservedDigits(temp3);
      // this.tinvForm.pinv_total = this.helper.haveFour(totalF)
      // this.tinvForm.pinv_tnweight = this.helper.reservedDigits(totalE)
      let temp2 = JSON.parse(JSON.stringify(totalF));
      temp2 = Math.round(Number(temp2) * 100) / 100;
      this.tinvForm.tinv_invtotal = this.helper.reservedDigits(temp2);
      this.tinvForm.prod_tnweight = this.helper.reservedDigits(totalD);
      this.tinvForm.pinv_num = this.helper.haveFour(totalA);
      // this.tinvForm.pinv_taxtotal = this.helper.haveFour(totalH)
      this.tinvForm.pinv_actu_nos = temp.join(',');
    },
    // 获取币种
    getCustDport() {
      get(optnAPI.getOptnByPermId, { perm_id: 10008 })
        .then(res => {
          if (res.data.code === 0) {
            this.CompanyList = res.data.data.form.optn_cntt_list;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    //获取公司抬头
    getCptt() {
      get(cpttAPI.getAllCpttsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.cpttList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    // 指定外销员list
    getStffUser() {
      get(stffAPI.getAllStffsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.stffList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 获取表单信息
    getTinvInfo() {
      const props = JSON.parse(UrlEncode.decode(this.$route.query.key));
      get(tinvAPI.getTinvById, { tinv_id: props.form_id })
        .then(res => {
          if (res.data.code === 0) {
            this.tinvForm = res.data.data.form;
            this.btn = res.data.data.btn;
            if (this.tinvForm.autoInc) this.btn.edit = false;
            this.stffForm.stff_name = this.tinvForm.stff_name;
            this.stffForm.dept_name = this.tinvForm.dept_name;
            this.stffForm.dept_team_name = this.tinvForm.dept_team_name;
            this.stffForm.user_id = this.tinvForm.user_id;
            this.stffForm.dept_id = this.tinvForm.dept_id;
            this.stffForm.stff_id = this.tinvForm.stff_id;
            this.tinvPartListCopy = JSON.parse(JSON.stringify(this.tinvForm.tinv_part_list));
            //用友抓取后不可反生效不可编辑
            this.$emit('isYongYou', false);
            this.yongYouStatus = '未抓取';
            if (this.tinvForm.nsaleBillVouch_Status === 1) {
              this.btn.edit = false;
              this.$emit('isYongYou', true);
              this.yongYouStatus = '已抓取';
            }
            this.calcChange();
            setTimeout(() => {
              this.loadingFlag = false;
            }, 500);
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 计算
    calcChange() {
      this.tinvForm.pinv_part_subtotal = this.helper.reservedDigits(this.tinvForm.pinv_part_subtotal);
      this.tinvForm.tinv_invtotal = this.helper.reservedDigits(this.tinvForm.tinv_invtotal);
      this.tinvForm.pinv_taxtotal = this.helper.reservedDigits(this.tinvForm.pinv_taxtotal);
      if (this.tinvForm.ppay_rpdate) {
        this.tinvForm.ppay_rpdate = Number(this.tinvForm.ppay_rpdate) * 1000;
      } else {
        this.tinvForm.ppay_rpdate = null;
      }
      if (this.tinvForm.pinv_date) {
        this.tinvForm.pinv_date = Number(this.tinvForm.pinv_date) * 1000;
      } else {
        this.tinvForm.pinv_date = null;
      }
      if (this.tinvForm.pinv_recdate) {
        this.tinvForm.pinv_recdate = Number(this.tinvForm.pinv_recdate) * 1000;
      } else {
        this.tinvForm.pinv_recdate = null;
      }
      for (let i = 0; i < this.tinvForm.tinv_actu_list.length; i++) {
        if (this.tinvForm.tinv_actu_list[i].pinv_actu_date) {
          this.tinvForm.tinv_actu_list[i].pinv_actu_date = Number(this.tinvForm.tinv_actu_list[i].pinv_actu_date) * 1000;
        } else {
          this.tinvForm.tinv_actu_list[i].pinv_actu_date = null;
        }
      }
    },
    // 获取币种
    getCurrency() {
      get(optnAPI.getOptnByPermId, { perm_id: 10015 }).then(res => {
        if (res.data.code === 0) {
          this.currencyList = res.data.data.form.optn_cntt_list;
        }
      });
    },
    exportExcel() {
      let requestBody = { tinv_id: this.tinvForm.tinv_id };
      tinvAPI.exportExcel(requestBody).then(({ data }) => {
        downloadFile({ fileUrl: data.pdf_url, fileName: data.fileName, type: '.pdf', open: true });
      });
    }
  }
};
</script>

<style scoped lang="scss">
.vd_bortop {
  border-top: #dcdfe6 solid 1px;
  padding-top: 20px;
}

.vd_edhea {
  position: relative;
}

.vd_export {
  position: absolute;
  top: 0;
  left: 65px;
}

.vd_bortop {
  border-top: #dcdfe6 solid 1px;
  padding-top: 20px;
}

.vd_dash {
  width: 100%;
  height: 1px;
  border-top: 1px dashed #e9e9e9;
  margin: 10px 0;
}

.vd_word {
  word-wrap: break-word;
  word-break: normal;
}

.vd_export2 {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 150px;
}
</style>
